<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12" sm="12">
        <iq-card>
          <template>
            <b-overlay :show="loading">
              <b-row>
                <b-col lg="12" sm="12" class="text-center mb-4">
                  <b-button variant="primary" @click="pdfExport" class="mr-2">
                    {{ $t('globalTrans.export_pdf') }}
                  </b-button>
                </b-col>
                <b-col lg="12" sm="12" class="text-center mb-4">
                  <h5 class='complain-title'>{{ $t('pump_install.supply_notel') }}</h5>
                </b-col>
                <b-col lg="2" sm="12">
                  <p class="text-dark">{{ $t('pump_install.supply_date') }} : </p>
                </b-col>
                <b-col lg="10" sm="12">
                  <p class="text-dark">{{ supply_note.supply_date | dateFormat }} </p>
                </b-col>
                <b-col lg="2" sm="12">
                  <p class="text-dark">{{ $t('pump_install.supply_notel') }} : </p>
                </b-col>
                <b-col lg="10" sm="12">
                  <p class="text-dark">{{  supply_note.supply_note }} </p>
                </b-col>
              </b-row>
            </b-overlay>
          </template>
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import RestApi, { irriSchemeServiceBaseUrl } from '../../../../../config/api_config'
import { supplyEquipmentDetails } from '../../api/routes'
import ExportPdf from './export_pdf_details_supply'
export default {
  name: 'FormLayout',
  props: ['id', 'orgId'],
  created () {
    if (this.id) {
      this.getSupplyEquipment()
    }
  },
  data () {
    return {
      supply_note: ''
    }
  },
  methods: {
    async getSupplyEquipment () {
      this.loading = true
      const result = await RestApi.getData(irriSchemeServiceBaseUrl, supplyEquipmentDetails + '/' + this.$props.id)
      if (result.success) {
        this.supply_note = result.data
      }
      this.loading = false
    },
    pdfExport () {
      const reportTitle = this.$i18n.locale === 'en' ? 'Supply Note' : 'সরবরাহ নোট'
      ExportPdf.exportPdfDetails(irriSchemeServiceBaseUrl, '/report-heading/detail', 3, reportTitle, this.supply_note, this, this.search, this.organizationName)
    }
  }
}
</script>
<style>
  .hidden_header {
    display: none
  }
  .card-border {
    border: 1px solid #b9bdc1;
    box-shadow: 1px 1px 6px -1px grey;
    background-color: #dee2e6;
  }
  [dir=ltr][mode=light] .card-body {
    flex: 1 1 auto;
    min-height: 1px;
    padding: 10px;
  }
  .report-name{
    font-weight: bold !important;
    text-transform: uppercase;
  }
  .my-btn{
    padding: 2px !important;
  }
</style>
