<template>
  <b-container fluid>
    <b-overlay :show="loading">
      <b-row v-show="dataLoading">
        <b-col lg="12" sm="12">
          <iq-card>
            <template>
              <b-row>
                <b-col lg="12" sm="12" class="text-center mb-4">
                <h5 class='complain-title'>{{ $t('pump_install.requisition_details') }}</h5>
                </b-col>
              </b-row>
              <b-row>
                <b-col lg="12" sm="12">
                  <b-row>
                    <b-col lg="3" sm="12">
                      <p class="text-dark">{{ $t('pump_install.organization') }} : </p>
                    </b-col>
                    <b-col lg="7" sm="12">
                        <p class="text-dark">{{ organizationName }}</p>
                    </b-col>
                    <b-col lg="2" sm="12">
                        <b-button class="btn btn-outline-success" variant="iq-bg-success" size="sm" title="Download" @click="pdfExport"><i class="ri-download-cloud-fill"></i> </b-button>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col lg="3" sm="12">
                      <p class="text-dark">{{ $t('pump_install.requisition_date') }} : </p>
                    </b-col>
                    <b-col lg="9" sm="12">
                        <p class="text-dark">{{ requisition.requisition_date | dateFormat }}</p>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col lg="3" sm="12">
                      <p class="text-dark">{{ $t('pump_install.requisition_id') }} : </p>
                    </b-col>
                    <b-col lg="9" sm="12">
                        <p class="text-dark">{{ requisition.requisition_id }}</p>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
              <b-row>
                <b-col lg="12" sm="12">
                  <b-table bordered :fields="columns" :items="items"></b-table>
                </b-col>
              </b-row>
              <b-row>
                <b-col lg="3" sm="12">
                  <p class="text-dark">{{ $t('pump_install.remarks') }} : </p>
                </b-col>
                <b-col lg="9" sm="12">
                  <p class="text-dark">{{ (this.$i18n.locale === 'bn') ? requisition.remarks_bn : requisition.remarks }}</p>
                </b-col>
              </b-row>
              <b-row v-if="approval_notes.length > 0">
                  <b-col lg="12" sm="12" class="text-center mb-4">
                  <h5 class='complain-title'>{{ $t('irri_pump_main.approval_note') }}</h5>
                  </b-col>
              </b-row>
              <b-row v-if="approval_notes.length > 0">
                <table class="table table-bordered" style="width: 100%;margin: 0px 12px;margin-bottom: 20px;">
                  <tr>
                    <th>{{ $t('irri_pump_main.note') }}</th>
                    <th>{{ $t('irri_pump_main.sender_name') }}</th>
                  </tr>
                  <tr v-for="(approvalNote,index) in approval_notes" :key="index">
                    <td>{{ ($i18n.locale === 'bn') ? approvalNote.note_bn : approvalNote.note }}</td>
                    <td>{{ getOfficeName(approvalNote.sender_id) }}</td>
                  </tr>
                </table>
              </b-row>
            </template>
          </iq-card>
        </b-col>
      </b-row>
    </b-overlay>
  </b-container>
</template>
<script>
import RestApi, { irriSchemeServiceBaseUrl } from '../../../../../config/api_config'
import { requisitionDetails } from '../../api/routes'
import ExportPdf from './export_pdf_details'

export default {
  name: 'FormLayout',
  props: ['id', 'scheme_application_id'],
  created () {
    if (this.id) {
      this.getRequisitionDetails()
    }
  },
  data () {
    return {
      loading: false,
      dataLoading: false,
      organizationName: '',
      requisition: '',
      requisition_details: '',
      items: [],
      approval_notes: []
    }
  },
  computed: {
    columns () {
      const labels = [
        { label: this.$t('pump_install.category') },
        { label: this.$t('pump_install.item_name') },
        { label: this.$t('pump_install.measurement_unit') },
        { label: this.$t('pump_install.stock_quantity') },
        { label: this.$t('pump_install.stock_out_quantity') },
        { label: this.$t('pump_install.current_stock') }
      ]

      const keys = [
        { key: 'category' },
        { key: 'item_name' },
        { key: 'measurement_unit' },
        { key: 'stock_quantity' },
        { key: 'stock_out_quantity' },
        { key: 'current_stock' }
      ]

      return labels.map((item, index) => {
          return Object.assign(item, keys[index])
      })
    }
  },
  methods: {
    async getRequisitionDetails () {
      this.loading = true
      const result = await RestApi.getData(irriSchemeServiceBaseUrl, requisitionDetails + '/' + this.$props.scheme_application_id + '/' + this.$props.id)
      if (result.success) {
        this.requisition = result.data.requistion
        this.approval_notes = result.data.approval_notes
        this.items = []
        this.items = this.formatList(result.data.requistion_details)
        this.getOrganization(this.requisition.org_id)
        this.dataLoading = true
      }
      this.loading = false
    },
    getOrganization (orgId) {
      const orgList = this.$store.state.orgList
      const tmpOrg = orgList.find(org => org.value === orgId)
      this.organizationName = this.$i18n.locale === 'bn' ? tmpOrg.text_bn : tmpOrg.text_en
    },
    formatList (data) {
      if (this.$i18n.locale === 'bn') {
        const listData = data.map(item => {
          const isActive = { isActive: true }
          const tmpCategory = { category: item.category_name_bn }
          const tmpItemName = { item_name: item.item_name_bn }
          const tmpUnit = { measurement_unit: item.unit_name_bn }
          const tmpStock = { stock_quantity: item.stock_quantity }
          const tmpStockOut = { stock_out_quantity: item.stock_out_quantity }
          const tmpCurrentStock = { current_stock: parseFloat(item.stock_quantity - item.stock_out_quantity) }
          return Object.assign({}, isActive, tmpCategory, tmpItemName, tmpUnit, tmpStock, tmpStockOut, tmpCurrentStock)
        })
        return listData
      } else {
        const listData = data.map(item => {
          const isActive = { isActive: true }
          const tmpCategory = { category: item.category_name }
          const tmpItemName = { item_name: item.item_name }
          const tmpUnit = { measurement_unit: item.unit_name }
          const tmpStock = { stock_quantity: item.stock_quantity }
          const tmpStockOut = { stock_out_quantity: item.stock_out_quantity }
          const tmpCurrentStock = { current_stock: parseFloat(item.stock_quantity - item.stock_out_quantity) }
          return Object.assign({}, isActive, tmpCategory, tmpItemName, tmpUnit, tmpStock, tmpStockOut, tmpCurrentStock)
        })
        return listData
      }
    },
    pdfExport () {
      const reportTitle = this.$i18n.locale === 'en' ? 'Requisition Details' : 'চাহিদাপত্র বিস্তারিত'
      ExportPdf.exportPdfDetails(irriSchemeServiceBaseUrl, '/report-heading/detail', 3, reportTitle, this.items, this.requisition, this, this.search, this.organizationName)
    }
  }
}
</script>
<style>
  .hidden_header {
    display: none
  }
  .card-border {
    border: 1px solid #b9bdc1;
    box-shadow: 1px 1px 6px -1px grey;
    background-color: #dee2e6;
  }
  [dir=ltr][mode=light] .card-body {
    flex: 1 1 auto;
    min-height: 1px;
    padding: 10px;
  }
  .report-name{
    font-weight: bold !important;
    text-transform: uppercase;
  }
  .my-btn{
    padding: 2px !important;
  }
</style>
