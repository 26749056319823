import i18n from '@/i18n'
import Store from '@/store'
// import ReportHeading from '@/Utils/report-head'
import pdfMake from 'pdfmake/build/pdfmake'
import pdfFontsBn from 'pdfmake/build/vfs_fonts_bn'
import pdfFontsEn from 'pdfmake/build/vfs_fonts_en'
import { dateFormat } from '@/Utils/fliter'
const exportPdfDetails = async (baseUrl, uri = '/report-heading/detail', orgId, reportTitle, data, requiData, vm, search, orgName) => {
  try {
      Store.commit('mutateCommonProperties', {
        loading: true
      })
      if (i18n.locale === 'bn') {
        pdfMake.vfs = pdfFontsBn.pdfMake.vfs
      } else {
          pdfMake.vfs = pdfFontsEn.pdfMake.vfs
      }
      const pdfContent = [
           ]
        pdfContent.push({ text: reportTitle, style: 'header2', alignment: 'center' })
        const allRowsHead = [
          [
            { text: vm.$t('pump_install.organization'), style: 'td', alignment: 'left' },
            { text: ':', style: 'td', alignment: 'center' },
            { text: (i18n.locale === 'bn') ? orgName : orgName, style: 'td', alignment: 'left' }
          ],
          [
            { text: vm.$t('pump_install.requisition_date'), style: 'td', alignment: 'left' },
            { text: ':', style: 'td', alignment: 'center' },
            { text: (i18n.locale === 'bn') ? dateFormat(requiData.requisition_date) : requiData.requisition_date, style: 'td', alignment: 'left' }
          ],
          [
            { text: vm.$t('pump_install.requisition_id'), style: 'td', alignment: 'left' },
            { text: ':', style: 'td', alignment: 'center' },
            { text: (i18n.locale === 'bn') ? vm.$n(requiData.requisition_id) : requiData.requisition_id, style: 'td', alignment: 'left' }
          ]
        ]
        pdfContent.push({
          table: {
            headerRows: 1,
            widths: ['15%', '2%', '50%'],
            body: allRowsHead
          },
          layout: {
            hLineWidth: function (i, node) {
              return 0
            },
            vLineWidth: function (i, node) {
              return 0
            }
          }
        })
        pdfContent.push({ text: '', style: 'fertilizer', alignment: 'center' })
        const allRows = [
          [
            { text: vm.$t('pump_install.category'), style: 'th', alignment: 'center' },
            { text: vm.$t('pump_install.item_name'), style: 'th', alignment: 'center' },
            { text: vm.$t('pump_install.measurement_unit'), style: 'th', alignment: 'center' },
            { text: vm.$t('pump_install.stock_quantity'), style: 'th', alignment: 'center' },
            { text: vm.$t('pump_install.stock_out_quantity'), style: 'th', alignment: 'center' },
            { text: vm.$t('pump_install.current_stock'), style: 'th', alignment: 'center' }
          ]
        ]
        data.map((report, index) => {
          allRows.push(
          [
            { text: report.category, style: 'td', alignment: 'center' },
            { text: vm.$n(report.current_stock), style: 'td', alignment: 'center' },
            { text: report.item_name, style: 'td', alignment: 'right' },
            { text: report.measurement_unit, style: 'td', alignment: 'center' },
            { text: vm.$n(report.stock_out_quantity), style: 'td', alignment: 'right' },
            { text: vm.$n(report.stock_quantity), style: 'td', alignment: 'right' }
          ])
        })
        pdfContent.push({
          table: {
            headerRows: 2,
            widths: ['10%', '20%', '20%', '20%', '20%', '10%'],
            body: allRows
          }
        })
        const allRowsHead2 = [
          [
            { text: vm.$t('pump_install.remarks'), style: 'td', alignment: 'left' },
            { text: ':', style: 'td', alignment: 'center' },
            { text: (i18n.locale === 'bn') ? requiData.remarks_bn : requiData.remarks, style: 'td', alignment: 'left' }
          ]
        ]
        pdfContent.push({
          table: {
            headerRows: 1,
            widths: ['15%', '2%', '13%'],
            body: allRowsHead2
          },
          layout: {
            hLineWidth: function (i, node) {
              return 0
            },
            vLineWidth: function (i, node) {
              return 0
            }
          }
        })
        const waterMarkText = i18n.locale === 'bn' ? 'কৃষি মন্ত্রণালয়' : 'Ministry of Agriculture'
        var docDefinition = {
        content: pdfContent,
        pageSize: 'A4',
        pageOrientation: 'portrait',
        watermark: { text: waterMarkText, color: 'blue', opacity: 0.1, bold: true, italics: false },
        styles: {
            th: {
              fontSize: 10,
              margin: [3, 3, 3, 3]
            },
            th1: {
                fontSize: 9
              },
            td: {
              fontSize: 8,
              margin: [3, 3, 3, 3]
            },
            search: {
              fontSize: 8,
              margin: [3, 3, 3, 3]
            },
            fertilizer: {
              margin: [0, 10, 0, 7]
            },
            fertilizerSHeader: {
                fontSize: 10,
                margin: [40, 0, 0, 0]
            },
            header: {
              fontSize: 12,
              margin: [0, 0, 0, 4]
            },
            header2: {
              fontSize: 10,
              margin: [0, 10, 0, 20]
            },
            headerPort1: {
              fontSize: 10,
              margin: [0, 20, 0, 5]
            },
            headerPort: {
              fontSize: 10,
              margin: [0, 4, 0, 15]
            },
            krishi: {
              margin: [0, 1, 0, 15],
              alignment: 'center'
            },
            header3: {
              fontSize: 9,
              margin: [0, 15, 0, 0]
            },
            address: {
              fontSize: 9,
              margin: [0, -10, 0, 0]
            },
            tableSubHead: {
              margin: [0, 5, 0, 15]
            }
          }
        }
        pdfMake.createPdf(docDefinition, null, null, null).print()
    } catch (error) {
      if (error) {}
    }
    Store.commit('mutateCommonProperties', {
      loading: false
    })
}
export default {
  exportPdfDetails
}
